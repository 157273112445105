import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { media } from "utils/Media"
import Img from "gatsby-image"
import Slider from "react-slick"
import Text from "components/shared/Text"
import speechBubble from "images/homepage-social-speechbubble.png"
import { Container } from "reactstrap"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import leftArrow from "images/icon-left.png"
import rightArrow from "images/icon-right.png"

const ReviewWrapper = styled.div`
    position: relative;

    /* &:before,
    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 75px;
        background: linear-gradient(90deg,#080b3e 0,rgba(0,0,0,0) 100%);
        
        z-index: 1;
        pointer-events: none;
    } */

    &::before {
        position: absolute;
        bottom: 0;
        width: 100%;
        height:8rem ;
        left: 0;
        right: 100%;
        background-color: #140603;
        background: linear-gradient(0deg,#140603 0, rgba(20, 6, 3, .85) 50%, rgba(0,0,0,0) 100%);
        content:"" ;
        z-index:1 ;
    }
    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        /* background: linear-gradient(90deg,#080b3e 0,rgba(0,0,0,0) 100%); */
        background: rgb(8, 11, 62, .5);
        
        z-index: 0;
        pointer-events: none;
    }
`

const ReviewContent = styled.div`
    position: absolute;
    bottom: 4rem;
    z-index: 1;
    width: 100%;
`

const ReviewSlider = styled(Slider)`
    .slick-list {
        overflow: visible;
    }

    .slick-dots {
        li {
            background-color: white;
            border-radius: 50%;
            height: 20px;
            width: 20px;
        }
    }

    .slick-next,
    .slick-prev {
        background-image: url("${rightArrow}");
        background-repeat: no-repeat;
        background-position: center;
        height: 50px;
        width: 30px;
        top: auto;
        bottom: -5rem;
        right: 1rem;
        z-index: 100;
        padding: 2rem;

        @media ${media.md} {
            right: 5rem;
        }

        @media ${media.lg} {
            right: 15rem;
        }


        &:before {
            display: none;
        }

        &:hover, &:focus {
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("${rightArrow}");
            opacity: .8;
        }
    }

    .slick-prev {
        background-image: url("${leftArrow}");
        left: 1rem;
        right: auto;

        @media ${media.md} {
            left: 5rem;
        }

        @media ${media.lg} {
            left: 15rem;
        }

        &:hover {
            background-repeat: no-repeat;
            background-image: url("${leftArrow}");
        }
    }
`

const ReviewItem = styled.div`
    position: relative;
    text-align: center;
    padding: 0 1.5rem;
    img {
        margin: 0 auto;
        display: block;
    }
`

const ReviewText = styled.div`
    position: absolute;
    left: 10%;
    width: 80%;
    max-width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

class Reviews extends Component {
    render() {
        const reviews = this.props.data.allReviewsJson.edges
        const reviewItems = reviews.map((review, i) => {
            return(
                <ReviewItem key={i}>
                    <img src={speechBubble} alt="" />
                    <ReviewText>
                        <div dangerouslySetInnerHTML={{ __html: review.node.text }} />
                        <Text sm bold className="mt-2 mb-0">{review.node.author}</Text>
                    </ReviewText>
                </ReviewItem>
            )
        })

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            focusOnSelect: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
            ]
        };

        return (
            <ReviewWrapper>
                <Img fluid={this.props.data.BgImage.childImageSharp.fluid} className="d-none d-md-block" />
                <Img fluid={this.props.data.BgImageMob.childImageSharp.fluid} className="d-md-none" />

                <ReviewContent>
                    <Container>
                        <h2 className="text-center">What our audience have to say!</h2>
                        <ReviewSlider {...settings}>
                            {reviewItems}
                        </ReviewSlider>
                    </Container>
                </ReviewContent>
            </ReviewWrapper>
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                allReviewsJson {
                    edges {
                        node {
                            text
                            author
                        }
                    }
                }
                BgImage: file(relativePath: { eq: "reviews-background.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
                BgImageMob: file(relativePath: { eq: "reviews-background-mob.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
            }
		`}
        render={data => (
            <Reviews data={data} />
        )}
    />
)