import React, { Component } from "react"
import { graphql } from 'gatsby'
import Layout from "components/Layout/Layout"
import Gallery from "components/Gallery/Gallery"
import GalleryNav from "components/Gallery/GalleryNav"
import Reviews from "components/Reviews"

class PhotoTemplate extends Component {
    render() {
        const data = this.props.data.photoPagesJson;
        return(
            <Layout logo={true}>
                <GalleryNav />
                <Gallery images={data.images} title={data.title} />
                <Reviews />
            </Layout>
        )
    }
}

export default PhotoTemplate

export const query = graphql`
query($id: String!) {
    photoPagesJson ( id: { eq: $id }) {
        id
        title
        images {
            thumbnail {
                childImageSharp  {
                    fluid(maxWidth: 443, quality: 95) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            image {
                publicURL
            }
            videoUrl
        }
    }
}
`