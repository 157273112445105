import React, { Component } from "react"
import { Col, Modal, ModalBody } from "reactstrap"
import styled from "styled-components"
import { media } from "utils/Media"
import Img from "gatsby-image"
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import btnClose from "images/btn-close-large.png"

const GalleryItem = styled.div`
    width: 50%;
    transition: all .4s ease;
    text-align: center;
    cursor: pointer;
    padding: 1rem;

    @media ${media.md} {
        width: 33%;
    }

    .imageWrap {
        position: relative;
    }

    .caption {
        position: absolute;
        background-color: rgba(0, 0, 0, .4);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        opacity: 0;
        transition: all .4s ease;
        padding: 1.5rem;
        text-align: center;
        color: white;
        font-weight: 700;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title {
        color: #f47920;
        font-weight: 700;
        font-size: 1.3rem;
        letter-spacing: 0;
        padding: 1rem;

        @media (min-width: 768px) {
            font-size: 1.8rem;
        }
    }
`

const GalleryWrap = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: wrap;
    background-color: rgba(70,22,59,.741176);
`

const ModalStyled = styled(Modal)`
    max-width: 1200px;
    width: calc(100% - 50px);
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
    .modal-content {
        background: transparent;
        border: none;
        border-radius: 0;
    }
    .modal-body {
        padding: 0;
    }
    .popup-modal__close {
        position: absolute;
        right: -1rem;
        top: -5rem;
        background-color: transparent;
        cursor: pointer;
        border: 0;
        z-index: 4;
        background-image: url("${btnClose}") !important;
        height: 80px;
        width: 80px;
    }
`

class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
            modalOpen: false
        };

        this.toggleModal = this.toggleModal.bind(this)
    }

    toggleModal(e) {
        e.preventDefault();

        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    render() {
        const { photoIndex, isOpen, modalOpen } = this.state
        const images = this.props.images
        const galleryItems = images.map((image, i) => {
            if (image.videoUrl !== null) {
                return (
                    <GalleryItem key={i} onClick={(e) => {
                        this.toggleModal(e)
                        this.setState({ videoUrl: image.videoUrl })
                    }}>
                        <div className="imageWrap">
                            <Img fluid={image.thumbnail.childImageSharp.fluid} fadeIn={false} />
                        </div>
                    </GalleryItem>
                )
            } else {
                return (
                    <GalleryItem key={i} onClick={() => this.setState({ isOpen: true, photoIndex: i })}>
                        <div className="imageWrap">
                            <Img fluid={image.thumbnail.childImageSharp.fluid} fadeIn={false} />
                        </div>
                    </GalleryItem>
                )
            }
        })

        return(
            <>
                {/* {this.props.title &&
                    <Col className="text-center pt-5" xs={12}>
                        <h2>{this.props.title}</h2>
                    </Col>
                } */}

                <Col className="pb-4" xs={12}>
                    <GalleryWrap>
                        {galleryItems}
                    </GalleryWrap>
                </Col>
                
                {modalOpen && (
                    <ModalStyled
                        isOpen={modalOpen}
                        toggle={this.toggleModal}
                        centered={true}
                    >
                        <button className="popup-modal__close" onClick={this.toggleModal}>
                            <div className="sr-only">Close</div>
                        </button>
                        <ModalBody>
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe width="560" height="315" src={this.state.videoUrl} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen className="embed-responsive-item" ></iframe>
                            </div>
                        </ModalBody>
                    </ModalStyled>
                )}
                
                {isOpen && (
                    <Lightbox
                        // imageTitle={images[photoIndex].node.title}
                        // imageCaption={images[photoIndex].node.caption}
                        enableZoom={false}
                        mainSrc={images[photoIndex].image.publicURL}
                        nextSrc={images[(photoIndex + 1) % images.length].image.publicURL}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length].image.publicURL}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                )}
            </>
        )
    }
}

export default Gallery