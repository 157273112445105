import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { media } from "utils/Media"
import underline from "images/underline.png"

const GalleryNavWrap = styled.ul`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    
    li {
        a {
            position: relative;
            text-decoration: none;
            padding: 1rem;
            color: white;
            font-size: 1.25rem;
            font-family: ${props => props.theme.font.family.condensedBold};
            display: block;
            text-align: center;
            
            @media ${media.md} {
                font-size: 1.75rem;
                padding: 1rem 1.5rem 1.5rem;
            }

            &:after {
                content: "";
                position: absolute;
                left: 50%;
                bottom: 0;
                width: 100%;
                height: 10px;
                background-image: url("${underline}");
                transform: translateX(-50%);
                background-repeat: no-repeat;
                background-position: 50% 50%;
                bottom: 1rem;
                z-index: 1;
                display: none;

                @media ${media.md} {
                    display: block;
                }
            }

            &.active {
                background-color: rgb(70,22,59);
            }

            &:hover {
                background-color: rgba(70,22,59,.741176);
            }
        }
    }
`

const GalleryNav = (props) => {

    const navItems = props.data.allPhotoPagesJson.edges.map(({node}, i) => {
        return(
            <li key={i}>
                <Link to={node.path} activeClassName="active">
                    {node.title}
                </Link>
            </li>
        )
    })

    return(
        <GalleryNavWrap>
            {navItems}
        </GalleryNavWrap>
    )
}


export default () => (
    <StaticQuery
        query={graphql`
            query {
                allPhotoPagesJson {
                    edges {
                        node {
                            path
                            title
                        }
                    }
                }
            }
		`}
        render={data => (
            <GalleryNav data={data} />
        )}
    />
)